<template>
  <div>
    <header-slot :clients-search="true" :global-search="true">
      <template v-if="changeNavAction == true" #actions>
        <b-row class="w-100 pb-2">
          <b-col>
            <b-button
              v-show="isModuleActualEqualTwo"
              v-if="!isOnlyLead"
              variant="success"
              @click="openModalSearch()"
            >
              <feather-icon
                icon="SearchIcon"
                size="15"
                class="mr-50 text-white"
              />Payments Search
            </b-button>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <!-- Title Search Global -->
          <!-- Input Search Global -->
          <b-col cols="12" class="pl-0">
            <b-input-group class="mr-1">
              <b-input-group-prepend>
                <b-button variant="outline-info"> GLOBAL SEARCH</b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchText"
                placeholder="Search by Name, Account or the last 4 digits of Phone number"
                @keyup.enter="search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="search">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        v-for="(item, index) in tabs"
        :id="'abcd_' + index"
        :key="index"
        :to="{ name: item.route }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        {{ item.name }}
        <span v-if="item.name === 'Archived'" class="ml-2">
          <span v-if="S_COUNTER_CLIENTS_ARCHIVED > 0">
            <feather-icon
              icon
              :badge="S_COUNTER_CLIENTS_ARCHIVED"
              badge-classes="badge-danger badge-glow"
            />
          </span>
        </span>
        <span v-if="item.name === 'Calls Round'" class="ml-2">
          <span v-if="S_COUNTER_CLIENTS_CALLS_ROUND > 0">
            <feather-icon
              icon
              :badge="S_COUNTER_CLIENTS_CALLS_ROUND"
              badge-classes="badge-danger badge-glow"
            />
          </span>
        </span>
      </b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
    <!-- Modal Search Global -->
    <modal-global-search
      v-if="modalGlobalSearch"
      :program-id="programId"
      :role-id="currentUser.role_id"
      :search-text="searchText"
      @closeModal="closeGlobalSearch"
    />
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
import ModalSearchPayment from "@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue";
// Import Components
import ModalGlobalSearch from "@/views/commons/components/clients/modals/ModalGlobalSearch.vue";

export default {
  components: {
    ModalGlobalSearch,
    ModalSearchPayment,
  },
  data() {
    return {
      searchText: "",
      modalGlobalSearch: false,
      changeNavAction: true,
      openModalSearchPayment: false,
      isOnlyLead: false,
      moduleActual: null,
      permitStatus: null,
      tabs: [
        {
          route: "tax-research-clients-main",
          name: "Clients",
        },
        {
          route: "tax-research-shared-tab",
          name: "Shared",
        },
        {
          route: "tax-research-clients-archived",
          name: "Archived",
        },
        {
          route: "tax-research-clients-calls",
          name: "Calls Round",
        },
      ],
      programId: 3,
    };
  },
  computed: {
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
    ...mapState({
      S_COUNTER_CLIENTS_ARCHIVED: (state) =>
        state.TaxResearchClients.S_COUNTER_CLIENTS_ARCHIVED,
      S_COUNTER_CLIENTS_CALLS_ROUND: (state) =>
        state.TaxResearchClients.S_COUNTER_CLIENTS_CALLS_ROUND,
      S_COUNTER_CLIENTS_MAIN: (state) =>
        state.TaxResearchClients.S_COUNTER_CLIENTS_MAIN,
    }),
  },
  async created() {
    await Promise.all([this.moduleId()]);
  },
  mounted() {
    this.getAccessModules();
  },
  methods: {
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
    changeNav(value) {
      this.changeNavAction = value;
    },
    async search() {
      this.modalGlobalSearch = true;
    },
    closeGlobalSearch() {
      this.modalGlobalSearch = false;
    },
  },
};
</script>

<style>
.bg_primary a {
  background: #0090e7;
  color: #fff !important;
}
</style>
